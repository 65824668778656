<template lang="pug">
ul.FcwSteps(:style="style")
  li.FcwSteps__item(
    v-for="item in items"
    :key="item.name"
    :class="getItemClasses(item)"
    :tabindex="item.disabled ? -1 : 0"
    @click="selectStep(item)"
    @keypress.enter="selectStep(item)"
  )
    span.FcwSteps__item__bullet(:class="getItemBulletClasses(item)")
    span.FcwSteps__item__name {{ item.label }}
</template>

<style lang="stylus">
$item-margin-bottom = rem(48)
$item-height = rem(40)

.FcwSteps
  position relative
  display flex
  flex-direction column
  align-items flex-start
  padding-left rem(24)

  &::before
    content ''
    position absolute
    height 'calc(100% - %s)' % rem(80)
    left 0
    top rem(16)
    width rem(2)
    background var(--FcwSteps--progressBackgroundColor)

  &::after
    content ''
    position absolute
    height 'calc(var(--FcwSteps--index) * %s)' % ($item-height + $item-margin-bottom)
    transition height 600ms ease-in-out
    left 0
    top rem(16)
    width rem(2)
    background var(--FcwSteps--progressColor)

.FcwSteps__item
  display flex
  align-items center
  margin-bottom $item-margin-bottom
  position relative
  height $item-height
  border-radius rem(16)
  color var(--FcwSteps--textColor)
  cursor pointer

  &:focus-visible
    outline-offset rem(4)
    outline solid rem(4) var(--FcwSteps--textColorActive)
    transition transform 0.5s var(--transition--ease-out), $outline-transition

  &--active
    color var(--FcwSteps--textColorActive)

  &--disabled
    color var(--FcwSteps--textColorDisabled)
    user-select none
    cursor default

.FcwSteps__item__bullet
  display flex
  justify-content center
  align-items center
  align-self flex-start
  top rem(16)
  position relative
  height rem(4)
  width rem(4)
  left rem(-25)
  border-radius 50%

  &::before
    content ''
    position absolute
    height rem(16)
    transition background 300ms, transform 300ms
    left 50%
    width rem(16)
    top rem(6)
    transform translate(-50%, -50%)
    border-radius 50%
    background var(--FcwSteps--bulletBackgroundColor)
    z-index 1

  &::after
    content ''
    position absolute
    height rem(4)
    transition background 300ms, transform 300ms
    left 50%
    width rem(4)
    top rem(6)
    transform translate(-50%, -50%)
    border-radius 50%
    background var(--FcwSteps--bulletColor)
    z-index 1

  &--active
    &::before
      transform scale(1.6) translate(-32%, -32%)
      background var(--FcwSteps--bulletBackgroundColorActive)
</style>

<script setup lang="ts">
export interface FcwStepItem {
  /**
   * Name of the step item
   */
  name: string;
  /**
   * Displayed label of the step
   */
  label: string;
  /**
   * Whether the step is disabled or not
   */
  disabled?: boolean;
}

export interface FcwStepsProps {
  /**
   * Array of strings
   */
  items: FcwStepItem[];
  /**
   * Active item name
   */
  modelValue: string;
}

const props = defineProps<FcwStepsProps>();

const activeItem = useVModelProxy({ props });

const style = computed<Style>(() => ({
  '--FcwSteps--index': String(
    props.items.findIndex(item => item.name === activeItem.value)
  ),
  '--FcwSteps--bulletColor': getCssColor('primary'),
  '--FcwSteps--bulletBackgroundColor': getCssColor('neutral--light-5'),
  '--FcwSteps--bulletBackgroundColorActive': getCssColor('primary--light-2'),
  '--FcwSteps--textColor': getCssColor('neutral--dark-4'),
  '--FcwSteps--textColorActive': getCssColor('primary'),
  '--FcwSteps--textColorDisabled': getCssColor('neutral--light-2'),
  '--FcwSteps--progressColor': getCssColor('primary'),
  '--FcwSteps--progressBackgroundColor': getCssColor('primary--light-2'),
}));

function getItemClasses(item: FcwStepItem): VueClasses {
  return {
    'FcwSteps__item--active': activeItem.value === item.name,
    'FcwSteps__item--disabled': item?.disabled ?? false,
  };
}

function getItemBulletClasses(item: FcwStepItem): VueClasses {
  return {
    'FcwSteps__item__bullet--active': activeItem.value === item.name,
  };
}

function selectStep(item: FcwStepItem): void {
  !item.disabled && (activeItem.value = item.name);
}
</script>
