<template lang="pug">
.profile-register
  .profile-register__header
    FcwRouterLink(
      :location="homepage"
      hide-arrow
    )
      FcwClientLogo
    FcwChip(
      v-if="isPreview"
      :label="t('common__preview_mode')"
    )

  .profile-register__sidebar
    .profile-register__steps
      FcwSteps(
        v-model="activeStep"
        :items="menuSteps"
      )
    FcwProfileFooter(
      :customer-service-title="t('footer__customer_service__title')"
      :customer-service-links="customerServiceLinks"
      :legal-links="legalLinks"
      :editor-text="t('footer__powered_by')"
    )
  .profile-register__content
    NuxtPage
  Teleport(to="body")
    FcwAppStoresFallback.profile-register__mobileFallback(v-if="isSmAndDown")
</template>

<style lang="stylus">
.profile-register
  display flex
  height 100vh
  overflow hidden

.profile-register__header
  position fixed
  left rem(24)
  top rem(24)
  color var(--color--primary)
  z-index 1

.profile-register__sidebar
  display flex
  flex-direction column
  flex-basis rem(288)
  flex-shrink 0
  padding rem(148) rem(48) rem(48)
  overflow hidden
  height 100%
  elevation-light(3)
  z-index 0

  > * + *
    margin-top rem(24)

.profile-register__steps
  height 100%
  overflow auto
  padding-left rem(16)
  padding-top rem(8)

.profile-register__content
  width 100%
  background var(--color--neutral--light-4)
  padding rem(80)
  padding-bottom rem(64)
  overflow auto

.profile-register__mobileFallback
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  z-index 10

  .FcwAppStoresFallback__mainContent
    margin-top rem(32)
</style>

<script setup lang="ts">
import { getSignupTrackingStep } from '@/lib/helpers/ampli';

import type {
  FcwProfileFooterCustomerServiceLink,
  FcwProfileFooterLegalLink,
} from '@/components/organisms/FcwProfileFooter.vue';

const { t } = useClientI18n();
const { clientState, homepage } = useClientStore();

const { isPreview } = useGlobalStore();

const { isSmAndDown } = useFBreakpoints();

const registerStore = useProfileRegisterStore();

const { track } = useUserTracking();

const route = useRoute();
const activeStep = computed<RegisterStepName>({
  get() {
    return route.meta.step as RegisterStepName;
  },
  async set(value) {
    await navigateTo({ name: `profile-register-${value}` });
  },
});

watch(activeStep, (oldStep, nextStep) => {
  track.moveThroughSignupStep({
    stepNameFrom: getSignupTrackingStep(oldStep),
    stepNameTo: getSignupTrackingStep(nextStep),
  });
});

const menuSteps = computed(() =>
  registerStore.steps.map(step => ({
    name: step.name,
    label: t(`register_step__${step.name.replace(/-/g, '_')}`),
    disabled: !registerStore.isStepAllowed(step.name),
  }))
);

const customerServiceLinks = computed<FcwProfileFooterCustomerServiceLink[]>(
  () => {
    return (
      [
        {
          icon: 'supportHelp',
          text: clientState.phoneNumber,
          location: `tel:${clientState.phoneNumber}`,
          type: 'tel',
        },
        {
          icon: 'mail',
          text: clientState.email,
          location: `mailto:${clientState.email}`,
          type: 'mailto',
        },
      ] as FcwProfileFooterCustomerServiceLink[]
    ).filter(link => link.text !== '');
  }
);

const legalLinks = computed<FcwProfileFooterLegalLink[]>(() => [
  {
    text: t('common__terms_and_conditions'),
    location: { name: 'terms-and-conditions' },
  },
  {
    text: t('common__legal_notice'),
    location: { name: 'legal-notice' },
  },
]);
</script>
